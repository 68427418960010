/* globals showAlert, logOut, isLoginPage, getConfig */
/**
 * @description Saves the passed server time to localStorage and compares with device time. Calls back if necessary
 * @param serverTime Date received from the server
 * @param {function} callback Function to run, passing a true false if the time is out of sync
 */
function saveAndCheckServerTime(serverTime, callback) {
  "use strict";
  /**
   * @param {configLoadedCallback}
   */
  getConfig(function(localConfig) {
    // Null check
    if (serverTime === null)
    {
      var oldServerTime = new Date(localStorage.getItem("settings/serverTime")).toLocaleString();
      var deviceTime = new Date().toLocaleString();
      showAlert("Warning. Could not check server time. Scanner Date: \n\r" + deviceTime + ". Server known at: \n\r" + oldServerTime,{type:"error",canClose:true,callback:function(){
        if (localConfig.allowNullServerTime) {
          callback(true);
        } else {
          callback(false);
          logOut(false);
        }
      }});
    } else
    {
      // Not null - Save the time and check it.
      localStorage.setVariable("settings/serverTime",serverTime, true);
      isTimeOutOfSync(serverTime, true, function(timeOutOfSync) {
        if (timeOutOfSync) {
          console.log("The time is out of sync with the server");
          showIncorrectTimeMessage(function() {
            callback(false);
            logOut(false);
          });
        } else {
          callback(true);
        }
      });
    }
  });
}

/**
 * @description Validates the time is correct and runs callback function
 * @param {function} callback
 */
function isScannerTimeCorrect(callback) {
  "use strict";
  if(localStorage.getItem("settings/serverTime")) {
    isTimeOutOfSync(localStorage.getItem("settings/serverTime"), false, function (timeIsOutOfSync) {
      callback(!timeIsOutOfSync);
    });
  }
  callback(true);
  //If the time cannot be found then we have no choice but to allow the time to be wrong.
}

/**
 * @description compares the server time with the client time
 * @parameter {date} serverTime the http request date object
 * @parameter {Boolean} online States if the function is called from where we know we are online to the network.
 * @parameter {function} Callback function to run when the checks have completed. Function arg is true if the time is out by 2 mins.
 */
function isTimeOutOfSync(serverTime,online, callback) {
  "use strict";
  /**
   * @param {configLoadedCallback}
   */
  getConfig(function(localConfig) {
    //Null Check
    if (serverTime === null) {
      showAlert("Warning. Could not check server time. Scanner Date: \n\r" + new Date().toLocaleString(),{type:"error",canClose:true,callback:function(){
        callback(!localConfig.allowNullServerTime); // return opposite of allowNull - if null allowed return false (time not out).
      }});
    }
    var serverDate = new Date(serverTime);
    var clientDate = new Date();
    var difference = serverDate - clientDate;
    // compare. if out of tolerance then panic!
    // If online we Math.abs to stop the scanner being too far into the future.
    if(online) {
      difference = Math.abs(difference);
    }
    callback(difference > 120000);
  });
}

/**
 * @description Shows a standard incorrect time message. If not at login, the user will be told they are logging out.
 * @param {function} callbackFunction
 */
function showIncorrectTimeMessage(callbackFunction) {
  "use strict";
  var knownServerDate = (localStorage.getItem("settings/serverTime")) ? localStorage.getItem("settings/serverTime") : null;
  var serverDate = (knownServerDate)? new Date(knownServerDate).toLocaleString() : "Unknown",
  deviceDate = new Date().toLocaleString(),
  additionalText = (!isLoginPage())? " You will be logged out." : "";
  showAlert("Scanner time and date is incorrect. \n Server: "+serverDate+"\n Device: "+deviceDate+additionalText,{callback:callbackFunction});
}